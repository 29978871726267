@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@200;300;400;500;600;700;800;900&display=swap');

* {
    font-family: "Lexend", sans-serif;
}

body {
    position: relative;
}

/*  */

.how-it-works {
    display: flex;
    justify-content: space-between;
    padding: 80px 0;
}

.how-card-header img {
    width: 100%;
}

.how-card {
    margin-right: 15px;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
    border-radius: 20px;
    height: 450px;
}

.work-head {
    text-align: center;
    font-weight: 500;
}

.wash {
    color: #266A26;
    font-weight: 500;
}

.how-card-body {
    padding: 15px 25px;
}

.how, .section-culture, .section-reach {
    background: #ECFFEC;
    padding: 50px 0 80px 0;
}

.section-reach {
    padding: 80px 0;
}



.how-card-body p {
    font-size: 14px;
}
@media screen and (max-width: 992px) {
    .how-it-works {
        flex-direction: column;
    }

    .how-card {
        margin-bottom: 15px;
        width: 100%;
        height: 100% !important;
        margin-bottom: 40px;
    }
    br {
        display: none;
    }
}



/* styling the hero section */
.section-hero, .section-full-video {
    width: 100%;
    display: block;
    height: 700px;
    /* margin: 0 0 30px 0; */
    position: relative;
}

.row {
    margin: 0 !important;
}

.hero-icons, .hero-icons-about {
    display: flex;
}

.hero-icons p, .hero-icons-about p {
    color: rgba(225, 221, 221, 0.636);
}

.hero-content, .hero-content-about {
    padding: 15% 80px;
    color: #fff;
}

.hero-content-about {
    position: absolute;
    right: 0;
}

.hero-content h1, .hero-content-about h1 {
    font-weight: 800;
}



.vbtn {
    outline: none;
    border: 0;
    box-shadow: none;
    background: #fff;
    color: #222;
}

.vbtn:hover {
    color: #266A26;
}

.video-card {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: 20px;
    border-radius: 15px;
    margin-bottom: 20px;
    height: 420px;
}

.video-card-header img {
    width: 100%;
}

.video-card-body {
    padding: 10px 0;
}

.view-section-btn button {
    outline: none;
    box-shadow: none;
    border: 0;
    padding: 10px 85px;
    background: #266A26;
    color: #fff;
}

.about-card-section {
    display: flex;
    justify-content: space-between;
}

.card-unit {
    padding: 30px 40px;
    background: #fff;
    border-radius: 20px;
}

.section-about {
    padding: 40px 0;
}

.project-text {
    margin: 10% auto;
}

.project-text2 {
    margin: 25% auto;
}

.project-text p {
    font-size: 14px;
}

.pohead {
    margin: 80px 0;
}

.au {
    width: 100%;
}

.vm-section {
    display: flex;
    justify-content: space-between;
}

@media screen and (max-width: 992px) {
    .about-card-section {
        flex-direction: column;
    }

    .card-unit-text {
        text-align: center;
    }

    .card-unit {
        padding: 35px;
        margin: 17px 30px;
    }

    .hero-content, .hero-content-about {
        padding: 15% 14px;
    }
    
    .hero-content h1, .hero-content-about h1 {
        font-size: 35px;
        margin-bottom: 60px;
    }

    .au {
        width: 100%;
    }
}

@media screen and (min-width: 700px) and (max-width: 1000px) {
    .video-card-header img {
        width: 100%;
    }
}

@media screen and (min-width: 1500px) {
    .how-card {
        height: 450px;
    }
}

/* modal code */
.mymodal {
    display: block;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.5);
}

/* .mymodal {
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    transition: 0.3s all;
    opacity: 1;
    pointer-events: auto;
    z-index: 1;
} */
  
.hide-mymodal {
    opacity: 0;
    pointer-events: none;
    z-index: -1;
}
  
.modal-content {
    margin: 10% auto;
    width: 80%;
    animation-name: modalopen;
    animation-duration: 2s;
    background: transparent;
    border: 0;
}

@media screen and (max-width: 650px) {
    .modal-content {
        width: 100%;
        margin: 50% auto;
    }
}

@media screen and (min-width: 700px) and (max-width: 1000px) {
    .modal-content {
        width: 100%;
        margin: 50% auto;
    }
}
  
.modal-header h2,
.modal-footer h3 {
  margin: 0;
}

.modal-header {
    padding: 15px;
    border: 0;
}

.modal-body {
    padding: 10px 20px;
}


.close {
    float: right;
    font-size: 30px;
    color: #fff;
}

.close:hover,
.close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}

@keyframes modalopen {
    from {
        opacity: 0;
    }
    to {
    opacity: 1;
    }
}

.whsap {
    display: flex;
    justify-content: space-between;
    background: #5BFF5B;
    border-radius: 30px;
    padding: 3px 10px;
}

.whsap img {
    height: 50px;
}

.whsap p {
    line-height: 2;
}

.logo {
    position: absolute;
    top: 50%;
    left: 34%;
    transform: translate(-50%, -50%);
    width: 80px; /* Adjust the size of the logo as needed */
    height: auto;
}  

@media screen and (max-width: 650px){
    .whsap p {
        display: none;
    }
    .whsap {
        background: transparent;
    }
}